import axios from "axios";


export const getAdscendOffers = async (userId) => {
    const { data } = await axios.get('api/adscendoffers', {
        headers: {
            "Content-Type": 'application/json'
        },
        params: {
            userId: userId
        }
    });
    return data;
};