import { Routes, Route } from 'react-router-dom';
import PublisherLogin from './components/PublisherPortal/PublisherLogin';
import PublisherRegister from './components/PublisherPortal/PublisherRegister';
import PublisherDashboard from './components/PublisherPortal/PublisherDashboard';

export default function PubPortalAppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<PublisherLogin />} />
            <Route path="/register" element={<PublisherRegister />} />
            <Route path="/dashboard" element={<PublisherDashboard />} />
        </Routes>
    );
}