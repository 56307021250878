import classes from './games.module.css';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import Button from '../../components/Button/Button';
import WallOffer from '../../components/WallOffer/WallOffer';
import { getUserGeo } from '../../services/userGeoService';
import { getLootablyGamingOffers } from '../../services/lootablyService';
import CategorySelector from '../Offers/CategorySelector';

const Games = () => {
    const { user } = useAuth();
    const [ip, setIP] = useState("");
    const [offers, setOffers] = useState([]);
    const [country, setCountry] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchIPAndCountry = async () => {
            try {
                const ipRes = await axios.get("https://api.ipify.org/?format=json");
                const fetchedIP = ipRes.data.ip;
                setIP(fetchedIP);

                const geoRes = await getUserGeo(fetchedIP);
                const fetchedCountry = geoRes.country_code2;
                setCountry(fetchedCountry);

                console.log("User IP is " + fetchedIP);
                console.log("User country is " + fetchedCountry);
            } catch (error) {
                setError(error.message ? error.message : JSON.stringify(error));
                setLoading(false);
            }
        };

        fetchIPAndCountry();
    }, []);

    useEffect(() => {
        const fetchOffers = async () => {
            if (user.id && ip) {
                try {
                    const lootablyData = await getLootablyGamingOffers(country);
                    const lootablyArray = lootablyData.data.offers;

                    const sortedOffers = lootablyArray.sort((a, b) => b.conversionRate - a.conversionRate);

                    const top30Offers = sortedOffers.slice(0, 30);

                    const transformLootablyOffers = top30Offers.map(offer => {
                        let reward;
                    
                        if (offer.type === "singlestep") {
                            reward = offer.currencyReward;
                        } else if (offer.type === "multistep") {
                            reward = offer.goals.reduce((sum, goal) => sum + goal.currencyReward, 0);
                        }

                        let events;
                        if (offer.type === "singlestep") {
                            events = [{
                                eventDescription: offer.description,
                                value: offer.currencyReward
                            }];
                        } else {
                            events = offer.goals.map(goal => ({
                                eventDescription: goal.description,
                                value: goal.currencyReward
                            }));
                        }
                        return {
                            id: offer.offerID,
                            title: offer.name,
                            description: offer.description,
                            devices: offer.devices,
                            imageUrl: offer.image,
                            link: offer.link.replace("{userID}", user.id),
                            reward: reward,
                            events: events
                        };
                    });

                    setOffers(transformLootablyOffers);
                } catch (error) {
                    setError(error.message ? error.message : JSON.stringify(error));
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchOffers();
    }, [user.id, ip, country]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
             <CategorySelector />
            <h2 className={classes.sponsoredOffersHeading}>Available Games</h2>
            <ul className={classes.list}>
                {offers.map(offer => (
                    <li key={offer.id} className={classes.item}>
                        <WallOffer
                            title_text={offer.title}
                            center_text={offer.description}
                            image_game={offer.imageUrl}
                            devices={offer.devices}
                            image_Trophy={'/trophy.png'}
                            link={offer.link}
                            reward_payout={offer.reward}
                            events={offer.events}
                            currencyName="coins"
                        />
                    </li>
                ))}
            </ul>
        </>
    );
};

export default Games;