import React, { useEffect, useReducer, useState } from 'react'
import Thumbnails from '../../components/Thumbnails/Thumbnails';
import { getOfferWalls } from '../../services/offerWallService';
import classes from './offersPage.module.css';
import { Link } from 'react-router-dom';
import StarRating from '../../components/StarRating/StarRating';
import Button from '../../components/Button/Button';
import { getRevlumOffers } from '../../services/revlumService';
import { getWannadsOffers } from '../../services/wannadsService';
import { getUserGeo } from '../../services/userGeoService';
import { getCpaLeadOffers } from '../../services/cpaLeadService';
import axios from 'axios';
import { useAuth } from '../../hooks/useAuth';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import WallOffer from '../../components/WallOffer/WallOffer';
import TopOffer from '../../components/TopOffer/TopOffer';
import { getAdscendOffers } from '../../services/adscendService';
import { getLootablyGamingOffers, getLootablyOffers } from '../../services/lootablyService';
import CategorySelector from './CategorySelector';


const initialState = { offerWalls: [] };

const reducer = (state, action) => {
    switch (action.type) {
        case 'OFFERWALLS_LOADED':
            return { ...state, offerWalls: action.payload };
        default:
            return state;
    }
}
export default function OffersPage() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { user } = useAuth();
    const { offerWalls } = state;
    const [ip, setIP] = useState("");
    const [gamingOffers, setGamingOffers] = useState([]);
    const [offers, setOffers] = useState([]);
    const [country, setCountry] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [surveys, setSurveys] = useState([]);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
      const fetchIPAndCountry = async () => {
          try {
              const ipRes = await axios.get("https://api.ipify.org/?format=json");
              const fetchedIP = ipRes.data.ip;
              setIP(fetchedIP);

              const geoRes = await getUserGeo(fetchedIP);
              const fetchedCountry = geoRes.country_code2;
              setCountry(fetchedCountry);

              console.log("User IP is " + fetchedIP);
              console.log("User country is " + fetchedCountry);
          } catch (error) {
              setError(error.message ? error.message : JSON.stringify(error));
              setLoading(false);
          }
      };

      fetchIPAndCountry();
  }, []);

  useEffect(() => {
      const fetchOffers = async () => {
          if (user.id && ip && country) {
              try {
                  const [lootablyGameData, lootablyOfferData] = await Promise.all([
                      getLootablyGamingOffers(country),
                      getLootablyOffers(country)
                  ]);

                 /* const transformAdscendOffers = adscendData.map(offer => ({
                    id: offer.offer_id,
                    title: offer.name,
                    description: offer.description,
                    imageUrl: offer.image_url,
                    link: offer.click_url,
                    reward: offer.currency_count
                }));*/

                    const lootablyArray = lootablyGameData.data.offers;
                    const sortedOffers = lootablyArray.sort((a, b) => b.conversionRate - a.conversionRate);
                    const top30Offers = sortedOffers.slice(0, 30);
                    const transformLootablyGames = top30Offers.map(offer => {
                        let reward;
                    
                        if (offer.type === "singlestep") {
                            reward = offer.currencyReward;
                        } else if (offer.type === "multistep") {
                            reward = offer.goals.reduce((sum, goal) => sum + goal.currencyReward, 0);
                        }

                        let events;
                        if (offer.type === "singlestep") {
                            events = [{
                                eventDescription: offer.description,
                                value: offer.currencyReward
                            }];
                        } else {
                            events = offer.goals.map(goal => ({
                                eventDescription: goal.description,
                                value: goal.currencyReward
                            }));
                        }
                        return {
                            id: offer.offerID,
                            title: offer.name,
                            description: offer.description,
                            devices: offer.devices,
                            imageUrl: offer.image,
                            link: offer.link.replace("{userID}", user.id),
                            reward: Number(reward),
                            events: events
                        };
                    });

                    const lootablyOfferArray = lootablyOfferData.data.offers;
                    const filteredOffers = lootablyOfferArray.filter(offer => 
                        !(offer.categories.includes("game") || offer.categories.includes("mobilegame") || offer.categories.includes("desktopgame"))
                    );
                    const sortedRegularOffers = filteredOffers.sort((a, b) => b.conversionRate - a.conversionRate).slice(0, 30);
                    const transformLootablyOffers = sortedRegularOffers.map(offer => {
                        let reward;
                    
                        if (offer.type === "singlestep") {
                            reward = offer.currencyReward;
                        } else if (offer.type === "multistep") {
                            reward = offer.goals.reduce((sum, goal) => sum + goal.currencyReward, 0);
                        }

                        let events;
                        if (offer.type === "singlestep") {
                            events = [{
                                eventDescription: offer.description,
                                value: offer.currencyReward
                            }];
                        } else {
                            events = offer.goals.map(goal => ({
                                eventDescription: goal.description,
                                value: goal.currencyReward
                            }));
                        }
                        return {
                            id: offer.offerID,
                            title: offer.name,
                            description: offer.description,
                            devices: offer.devices,
                            imageUrl: offer.image,
                            link: offer.link.replace("{userID}", user.id),
                            reward: reward,
                            events: events
                        };
                    });

              const allGames = [...transformLootablyGames];
              const uniqueGames = Array.from(new Set(allGames.map(offer => offer.title)))
                                  .map(title => allGames.find(offer => offer.title === title));
              const allOffers = [...transformLootablyOffers];
              const uniqueOffers = Array.from(new Set(allOffers.map(offer => offer.title)))
                                  .map(title => allOffers.find(offer => offer.title === title));
              setGamingOffers(uniqueGames);
              setOffers(uniqueOffers);
              } catch (error) {
                  setError(error.message ? error.message : JSON.stringify(error));
              } finally {
                  setLoading(false);
              }
          }
          };
          fetchOffers();
  }, [user.id, ip, country]);

    useEffect(() => {
        getOfferWalls().then(offerWalls => dispatch({ type: 'OFFERWALLS_LOADED', payload: offerWalls}));
    }, []);

    useEffect(() => {
        const fetchSurveys = async () => {
            try {
                const CPXResponse = await fetch(`https://live-api.cpx-research.com/api/get-surveys.php?app_id=21139&&ext_user_id=${user.id}&output_method=api&ip_user=${ip}&limit=30`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    params: {
                        app_id: 21139,
                        ext_user_id: user.id,
                        output_method: 'api',
                        ip_user: ip,
                        limit: 30
                    },
                });
                const CPXData = await CPXResponse.json();
                setSurveys(CPXData.surveys);
            } catch (error) {
                setError(error.message ? error.message : JSON.stringify(error));
            }
        };

        fetchSurveys();
    }, [user.id, ip]);

    // Update screenWidth when window resizes
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Adjust number of items based on screen width
    const topGames = gamingOffers
        .sort((a, b) => b.reward - a.reward)
        .slice(0, screenWidth <= 425 ? 10 : 25);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: isMobile ? 1 : 4,
        slidesToScroll: isMobile ? 1 : 4,
        responsive: [
            {
                breakpoint: 1070,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className={classes.pageContainer}>
        <CategorySelector />
        <h2 className={classes.sponsoredOffersHeading}>Top Games</h2>
        <hr className={classes.line}/>
        <Slider {...settings}>
                {topGames.map(offer => (
                    <div key={offer.id}>
                        <TopOffer
                            title_text={offer.title}
                            center_text={offer.description}
                            image_game={offer.imageUrl}
                            devices={offer.devices}
                            image_Trophy={'/trophy.png'}
                            link={offer.link}
                            reward_payout={offer.reward}
                            events={offer.events}
                        />
                    </div>
                ))}
                </Slider>{/*
              <h2 className={classes.sponsoredOffersHeading}>Top Offers</h2>
              <hr className={classes.line}/>
              <Slider {...settings}>
                      {offers.map(offer => (
                          <div key={offer.id}>
                              <TopOffer
                                  title_text={offer.title}
                                  center_text={offer.description}
                                  image_game={offer.imageUrl}
                                  devices={offer.devices}
                                  image_Trophy={'/trophy.png'}
                                  link={offer.link}
                                  reward_payout={offer.reward}
                                  events={offer.events}
                              />
                          </div>
                      ))}
                  </Slider>*/}
        <h2 className={classes.sponsoredOffersHeading}>Top Surveys</h2>
        <hr className={classes.line}/>
        <Slider {...settings}>
            {surveys.map(survey => (
                <div key={survey.id}>
                    <TopOffer
                        title_text={"CPX Research Survey"}
                        center_text={"Complete this survey to earn coins!"}
                        image_game={"logo2-cpx-reserach.png"}
                        devices={['*']}
                        link={survey.href_new}
                        reward_payout={survey.payout}
                        events={[{
                            eventDescription: "Complete the survey!",
                            value: survey.payout
                        }]}
                    />
                </div>
            ))}
        </Slider>

        <h2 className={classes.sponsoredOffersHeading}>Offer Partners</h2>
        <hr className={classes.line}/>
        <Thumbnails offerWalls={offerWalls}/>
        <h2 className={classes.sponsoredOffersHeading}>Survey Partners</h2>
        <hr className={classes.line}/>
        <ul className={classes.list}>

        <li key={'cpx'}>
            <Link to={`/survey/cpx`}>
                <img
              className={classes.image}
              src={`logo2-cpx-reserach.png`}
              alt={"CPX Research"}
            />
          <div className={classes.content}>
            <div className={classes.stars}>
                <StarRating stars={5} />
            </div>
          </div>
          </Link>
        </li>
    </ul>
    </div>
    )
}