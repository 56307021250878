import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import classes from './redeemMethod.module.css';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import CannotAffordModal from '../Modal/CannotAffordModal';
import { useAuth } from '../../hooks/useAuth';

export default function RedeemPaypalMethod() {


    const [openModal, setOpenModal] = useState(false);
    const [openCannotAffordModal, setOpenCannotAffordModal] = useState(false);
    const [selectedCoinAmount, setSelectedCoinAmount] = useState(null);
    const [selectedMethod, setSelectedMethod] = useState(null);


    const { user } = useAuth();

    const [coins, setCoins] = useState(user?.coins || 0); // Initialize with existing coins

    useEffect(() => {
        const pollData = async () => {
            if (user) {
            const response = await axios.get(`https://www.rewardrocket.app/api/users/coins-count?user_id=${user.id}`);
            setCoins(response.data.coins); // Assuming response contains updated coins
            }
        };

        const intervalId = setInterval(pollData, 5000); // Poll every 5 seconds

        return () => clearInterval(intervalId); // Clear interval on unmount
    }, [user]); // Re-run effect if user changes


  return (
    <ul className={classes.list}>
        <li>
            <a>
            <span className={classes.title}>$10 USD Paypal</span>
                <img
              className={classes.image}
              src={`/paypal-svgrepo-com.svg`}
              alt={"paypal"}
            />
          <div className={classes.content}>
          <img
              className={classes.coinImage}
              src={`/purplecoin.svg`}
              alt={"coin"}
            />
            <span className={classes.popular}>1000 Coins</span>
          </div>
          <Button width={"6rem"} height={"2rem"} text={"Claim"} onClick={() => {
            setSelectedMethod("paypal");
            setSelectedCoinAmount(1000);
            if (coins >= 1000) {
                setOpenModal(true);
            } else {
                setOpenCannotAffordModal(true);
            }  
            }}/>
          </a>
        </li>
        <li>
            <a>
            <span className={classes.title}>$15 USD Paypal</span>
                <img
              className={classes.image}
              src={`/paypal-svgrepo-com.svg`}
              alt={"paypal"}
            />
          <div className={classes.content}>
          <img
              className={classes.coinImage}
              src={`/purplecoin.svg`}
              alt={"coin"}
            />
            <span className={classes.popular}>1500 Coins</span>
          </div>
          <Button width={"6rem"} height={"2rem"} text={"Claim"} onClick={() => {
            setSelectedMethod("paypal");
            setSelectedCoinAmount(1500);
            if (coins >= 1500) {
                setOpenModal(true);
            } else {
                setOpenCannotAffordModal(true);
            }  
            }}/>
          </a>
        </li>
        <li>
            <a>
            <span className={classes.title}>$25 USD Paypal</span>
                <img
              className={classes.image}
              src={`/paypal-svgrepo-com.svg`}
              alt={"paypal"}
            />
          <div className={classes.content}>
          <img
              className={classes.coinImage}
              src={`/purplecoin.svg`}
              alt={"coin"}
            />
            <span className={classes.popular}>2500 Coins</span>
          </div>
          <Button width={"6rem"} height={"2rem"} text={"Claim"} onClick={() => {
            setSelectedMethod("paypal");
            setSelectedCoinAmount(2500);
            if (coins >= 2500) {
                setOpenModal(true);
            } else {
                setOpenCannotAffordModal(true);
            }  
            }}/>
          </a>
        </li>
        <li>
            <a>
            <span className={classes.title}>$50 USD Paypal</span>
                <img
              className={classes.image}
              src={`/paypal-svgrepo-com.svg`}
              alt={"paypal"}
            />
          <div className={classes.content}>
          <img
              className={classes.coinImage}
              src={`/purplecoin.svg`}
              alt={"coin"}
            />
            <span className={classes.popular}>5000 Coins</span>
          </div>
          <Button width={"6rem"} height={"2rem"} text={"Claim"} onClick={() => {
            setSelectedMethod("paypal");
            setSelectedCoinAmount(5000);
            if (coins >= 5000) {
                setOpenModal(true);
            } else {
                setOpenCannotAffordModal(true);
            }  
            }}/>
          </a>
        </li>
        {openModal && <Modal closeModal={setOpenModal} coin_amount={selectedCoinAmount} method={selectedMethod}/>}
        {openCannotAffordModal && <CannotAffordModal closeModal={setOpenCannotAffordModal}/>}
    </ul>
  );
}