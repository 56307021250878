import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import RecentEarnerCard from '../RecentEarnerCard/RecentEarnerCard';
import RecentEarnerCarousel from '../RecentEarnerCard/RecentEarnerCarousel';
import classes from './offerWallHeader.module.css';

export default function OfferWallHeader() {

    return <header className={classes.header}>
        <div className={classes.container}>
            <Link to="/" className={classes.logo}>
            <img
              src={`/rocketwallnoback.png`}
              alt={'logo'}
            />
            </Link>
            <nav>
                <ul>
                    <Link to="mailto:cashoutnowllc@gmail.com">Support</Link>
                </ul>
            </nav>
        </div>
    </header>;
}