import { useState, createContext, useContext } from 'react';
import * as userService from '../services/userService';
import * as publisherService from '../services/publisherService';
import { toast } from 'react-toastify';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(userService.getUser());

    const login = async (email, password) => {
        try {
            const user = await userService.login(email, password);
            setUser(user);
            toast.success('Login Successful');
        } catch (err) {
            toast.error(err.response.data);
        }
    };

    const publisherLogin = async (email, password) => {
        try {
            const user = await publisherService.publisherLogin({ email, password });
            setUser(user);
            toast.success('Login Successful');
        } catch (err) {
            toast.error(err.response.data);
        }
    };

    const register = async data => {
        try {
            const user = await userService.register(data);
            setUser(user);
            toast.success('Register Successful');
        } catch (err) {
            toast.error(err.response.data);
        }
    };

    const publisherRegister = async data => {
        try {
            const user = await publisherService.publisherRegister(data);
            setUser(user);
            toast.success('Register Successful');
        } catch (err) {
            toast.error(err.response.data);
        }
    };

    const logout = () => {
        userService.logout();
        setUser(null);
        toast.success('Logout Successful');
    };

    const updateCoins = async user => {
        const updatedUser = await userService.updateCoins(user);
        setUser(updatedUser);
    }

    return (
        <AuthContext.Provider value={{ user, login, publisherLogin, logout, register, publisherRegister, updateCoins }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);