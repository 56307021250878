import React, { useEffect, useState, useRef } from 'react'
import { Helmet } from 'react-helmet'
import WallOffer from '../../components/WallOffer/WallOffer'
import classes from './offerWall.module.css'
import axios from 'axios';
import { getUserGeo } from '../../services/userGeoService';
import { getLootablyGamingOffers } from '../../services/lootablyService';
import { useParams } from 'react-router-dom';
import { getAdscendOffers } from '../../services/adscendService';
import { getPlacement } from '../../services/publisherService';

export default function OfferWall() {
    const { publisherId, placementId, userIdParam } = useParams();
    const [ip, setIP] = useState("");
    const [offers, setOffers] = useState([]);
    const [country, setCountry] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currencyName, setCurrencyName] = useState("coins");
    const userID = publisherId && placementId && userIdParam 
        ? `${publisherId}|---|${placementId}|---|${userIdParam}` 
        : "demo";

    console.log("Publisher ID:", publisherId);
    console.log("Placement ID:", placementId);
    console.log("User ID:", userIdParam);
    console.log("Generated userID:", userID);

    const adscendDeviceMap = {
      1: ["android"],
      2: ["iphone"]
    };

    useEffect(() => {
        const fetchPlacementDetails = async () => {
            if (publisherId && placementId) {
                try {
                    const placement = await getPlacement(publisherId, placementId);
                    console.log(placement);
                    if (placement && placement.currencyPlural) {
                        setCurrencyName(placement.currencyPlural.toLowerCase());
                    }
                } catch (error) {
                    console.error('Error fetching placement details:', error);
                }
            }
        };
        fetchPlacementDetails();
    }, [publisherId, placementId]);

    useEffect(() => {
        const fetchIPAndCountry = async () => {
            try {
                const ipRes = await axios.get("https://api.ipify.org/?format=json");
                const fetchedIP = ipRes.data.ip;
                setIP(fetchedIP);

                const geoRes = await getUserGeo(fetchedIP);
                const fetchedCountry = geoRes.country_code2;
                setCountry(fetchedCountry);

                console.log("User IP is " + fetchedIP);
                console.log("User country is " + fetchedCountry);
            } catch (error) {
                setError(error.message ? error.message : JSON.stringify(error));
                setLoading(false);
            }
        };

        fetchIPAndCountry();
    }, []);

    useEffect(() => {
        const fetchAllOffers = async () => {
            if (userID && ip) {
                try {
                    // Fetch Lootably offers
                    const lootablyData = await getLootablyGamingOffers(country);
                    const lootablyArray = lootablyData.data.offers;

                    const transformedLootablyOffers = lootablyArray.map(offer => {
                        let reward;
                        if (offer.type === "singlestep") {
                            reward = offer.currencyReward;
                        } else if (offer.type === "multistep") {
                            reward = offer.goals.reduce((sum, goal) => sum + goal.currencyReward, 0);
                        }

                        let events;
                        if (offer.type === "singlestep") {
                            events = [{
                                eventDescription: offer.description,
                                value: offer.currencyReward
                            }];
                        } else {
                            events = offer.goals.map(goal => ({
                                eventDescription: goal.description,
                                value: goal.currencyReward
                            }));
                        }
                        
                        return {
                            id: `lootably-${offer.offerID}`,
                            title: offer.name,
                            description: offer.description,
                            devices: offer.devices,
                            imageUrl: offer.image,
                            link: offer.link.replace("{userID}", userID),
                            reward: reward,
                            events: events,
                            conversion_rate: offer.conversionRate
                        };
                    });

                    // Fetch Adscend offers
                    const adscendData = await getAdscendOffers(userID);
                    const adscendArray = adscendData.offers;

                    const transformedAdscendOffers = adscendArray.map(offer => {
                        let reward;
                        if (offer.events.length === 0) {
                            reward = offer.currency_count;
                        } else if (offer.events.length > 0) {
                            reward = offer.events.reduce((sum, goal) => sum + goal.event_revenue * 100, 0);
                        }
                        
                        let events;
                        if (offer.events.length === 0) {
                            events = [{
                                eventDescription: offer.description != "" ? offer.description : "Complete the task.",
                                value: offer.currency_count
                            }];
                        } else {
                            events = offer.events.map(goal => ({
                                eventDescription: goal.event_name,
                                value: goal.event_revenue * 100
                            }));
                        }
                        
                        let devices = offer.mobile_app ? adscendDeviceMap[offer.mobile_app.platform] : ['*'];
                        return {
                            id: `adscend-${offer.offer_id}`,
                            title: offer.name,
                            description: offer.description,
                            devices: devices,
                            imageUrl: offer.image_url,
                            link: offer.click_url,
                            reward: reward,
                            events: events,
                            conversion_rate: offer.conversion_rate
                        };
                    });

                    // Combine and sort all offers
                    const allOffers = [...transformedLootablyOffers, ...transformedAdscendOffers];
                    const sortedOffers = allOffers.sort((a, b) => b.conversion_rate - a.conversion_rate);
                    
                    // Take top 30 offers
                    const top40Offers = sortedOffers.slice(0, 40);
                    setOffers(top40Offers);
                    console.log(offers)

                } catch (error) {
                    setError(error.message ? error.message : JSON.stringify(error));
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchAllOffers();
    }, [userID, ip, country]);

    return (
        <div className={classes.container}>
            <ul className={classes.list}>
                {offers.map(offer => (
                    <li key={offer.id} className={classes.item}>
                        <div className={classes.offerWrapper}>
                            <WallOffer
                                title_text={offer.title}
                                center_text={offer.description}
                                image_game={offer.imageUrl}
                                devices={offer.devices}
                                image_Trophy={'/trophy.png'}
                                link={offer.link}
                                reward_payout={offer.reward}
                                events={offer.events}
                                currencyName={currencyName}
                            />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}