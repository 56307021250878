import React, { useEffect, useState } from 'react'
import classes from './redeemPage.module.css';
import RedeemMethod from '../../components/RedeemMethod/RedeemMethod';
import { useAuth } from '../../hooks/useAuth';
import axios from 'axios';

export default function RedeemPage() {

    return (
        <>
        <h2 className={classes.sponsoredOffersHeading}>Choose your Reward Method!</h2>
        <h3 className={classes.info}>1. Select the reward you want below.</h3>
        <h3 className={classes.info}>2. Once we review your activity, you'll receive an email with your reward.</h3>
        <h3 className={classes.info}>3. Enjoy your winnings!</h3>
        <RedeemMethod/>
        </>
    )
}
