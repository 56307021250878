import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import classes from './publisherAuth.module.css';

export default function PublisherLogin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { user, publisherLogin } = useAuth();

    const submit = async (e) => {
        e.preventDefault();
        try {
            await publisherLogin(email, password);
            navigate('/dashboard');
        } catch (err) {
            console.log(err);
            toast.error(err.response?.data || 'An error occurred');
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.formContainer}>
                <h1 className={classes.title}>Publisher Login</h1>
                <form onSubmit={submit} className={classes.form}>
                    <div className={classes.inputGroup}>
                        <label className={classes.label}>Email</label>
                        <input
                            type="email"
                            className={classes.input}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className={classes.inputGroup}>
                        <label className={classes.label}>Password</label>
                        <input
                            type="password"
                            className={classes.input}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className={classes.button}>
                        Login
                    </button>
                    <p className={classes.linkText}>
                        Don't have an account? <Link to="/register" className={classes.link}>Register here</Link>
                    </p>
                </form>
            </div>
        </div>
    );
}
