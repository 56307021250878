import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import classes from './publisherAuth.module.css';

export default function PublisherRegister() {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        companyName: '',
        telegramId: '',
        skypeId: ''
    });
    const navigate = useNavigate();
    const { publisherRegister } = useAuth();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const submit = async (e) => {
        e.preventDefault();
        try {
            await publisherRegister(formData);
            navigate('/dashboard');
        } catch (err) {
            console.log(err);
            toast.error(err.response?.data || 'An error occurred');
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.formContainer}>
                <h1 className={classes.title}>Publisher Registration</h1>
                <form onSubmit={submit} className={classes.form}>
                    <div className={classes.inputGroup}>
                        <label className={classes.label}>Email</label>
                        <input
                            type="email"
                            className={classes.input}
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className={classes.inputGroup}>
                        <label className={classes.label}>Password</label>
                        <input
                            type="password"
                            className={classes.input}
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className={classes.inputGroup}>
                        <label className={classes.label}>Company Name</label>
                        <input
                            type="text"
                            className={classes.input}
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className={classes.inputGroup}>
                        <label className={classes.label}>Telegram ID (Optional)</label>
                        <input
                            type="text"
                            className={classes.input}
                            name="telegramId"
                            value={formData.telegramId}
                            onChange={handleChange}
                        />
                    </div>
                    <div className={classes.inputGroup}>
                        <label className={classes.label}>Skype ID (Optional)</label>
                        <input
                            type="text"
                            className={classes.input}
                            name="skypeId"
                            value={formData.skypeId}
                            onChange={handleChange}
                        />
                    </div>
                    <button type="submit" className={classes.button}>
                        Register
                    </button>
                    <p className={classes.linkText}>
                        Already have an account? <Link to="/" className={classes.link}>Login here</Link>
                    </p>
                </form>
            </div>
        </div>
    );
}
