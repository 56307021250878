import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import Title from '../../components/Title/Title';
import Button from '../../components/Button/Button';
import classes from './referralsPage.module.css';

export default function ReferralsPage() {
    const { user } = useAuth();
    const [copied, setCopied] = useState(false);
    
    const referralLink = `${window.location.origin}/register?ref=${user?.id}`;
    
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(referralLink);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    return (
        <div className={classes.container}>
            <Title title="Refer Friends & Earn" margin="2rem" />
            <div className={classes.content}>
                <p className={classes.description}>
                    Share your unique referral link and earn 25% commission for life on your referred users' earnings!
                </p>
                <div className={classes.linkContainer}>
                    <input 
                        type="text" 
                        value={referralLink} 
                        readOnly 
                        className={classes.linkInput}
                    />
                    <Button type="button" onClick={copyToClipboard}>
                        {copied ? 'Copied!' : 'Copy Link'}
                    </Button>
                </div>
                <div className={classes.infoBox}>
                    <h3>How it works:</h3>
                    <ol>
                        <li>Share your unique referral link with friends</li>
                        <li>Friends sign up using your link</li>
                        <li>You earn 25% commission on their earnings forever!</li>
                    </ol>
                </div>
            </div>
        </div>
    );
}
