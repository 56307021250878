import AppRoutes from "./AppRoutes";
import OfferWallAppRoutes from "./OfferWallAppRoutes";
import PubPortalAppRoutes from "./PubPortalAppRoutes";
import Header from "./components/Header/Header"
import Loading from "./components/Loading/Loading";
import { useLoading } from "./hooks/useLoading";
import { setLoadingInterceptor } from './interceptors/loadingInterceptor';
import { useEffect } from "react";
import Footer from "./components/Footer/Footer";
import OfferWallHeader from "./components/OfferWallHeader/OfferWallHeader";
import PubHeader from "./components/PubHeader/PubHeader";

function App() {
  const { showLoading, hideLoading } = useLoading();
  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0];

  useEffect(() => {
    setLoadingInterceptor({ showLoading, hideLoading });
  }, []);

  useEffect(() => {
    if (subdomain !== 'pub') {
      document.body.classList.add('with-background-image');
    } else {
      document.body.classList.remove('with-background-image');
    }
  }, [subdomain]);

  return (<>
  {subdomain === 'offerwall' ? (
        <>
        <OfferWallHeader/>
        <OfferWallAppRoutes />  
        </>
      ) : subdomain === 'pub' ? (
        <>
        <PubHeader/>
        <PubPortalAppRoutes />
        </>
      ) : (
        <>
        <Header/>
        <AppRoutes />
        </>
      )}
  <Footer/>
  </>)
}

export default App;
