import { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import classes from './publisherDashboard.module.css';
import Placements from './Placements';
import { getPlacements } from '../../services/publisherService';

export default function PublisherDashboard() {
    const { user } = useAuth();
    const [activeTab, setActiveTab] = useState('dashboard');
    const [placements, setPlacements] = useState([]);

    useEffect(() => {
        const fetchPlacements = async () => {
            try {
                const fetchedPlacements = await getPlacements();
                setPlacements(fetchedPlacements);
            } catch (error) {
                console.error('Error fetching placements:', error);
            }
        };
        fetchPlacements();
    }, []);

    const DashboardContent = () => (
        <>
            <div className={classes.statsGrid}>
                <div className={classes.statCard}>
                    <div className={classes.statValue}>$0.00</div>
                    <div className={classes.statLabel}>Total Revenue</div>
                </div>
                <div className={classes.statCard}>
                    <div className={classes.statValue}>0</div>
                    <div className={classes.statLabel}>Total Conversions</div>
                </div>
                <div className={classes.statCard}>
                    <div className={classes.statValue}>{placements.length}</div>
                    <div className={classes.statLabel}>Active Placements</div>
                </div>
                <div className={classes.statCard}>
                    <div className={classes.statValue}>$0.00</div>
                    <div className={classes.statLabel}>Pending Payout</div>
                </div>
            </div>
        </>
    );

    return (
        <div className={classes.container}>
            <h1 className={classes.welcome}>Welcome, {user?.companyName}</h1>
            
            <div className={classes.tabs}>
                <div 
                    className={`${classes.tab} ${activeTab === 'dashboard' ? classes.active : ''}`}
                    onClick={() => setActiveTab('dashboard')}
                >
                    Dashboard
                </div>
                <div 
                    className={`${classes.tab} ${activeTab === 'placements' ? classes.active : ''}`}
                    onClick={() => setActiveTab('placements')}
                >
                    Placements
                </div>
            </div>

            {activeTab === 'dashboard' ? <DashboardContent /> : <Placements />}
        </div>
    );
}
