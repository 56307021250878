import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import classes from './pubHeader.module.css';

export default function PubHeader() {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    return <header className={classes.header}>
        <div className={classes.container}>
            <Link to="/" className={classes.logo}>
                <img
                    src={`/rocketwallnoback.png`}
                    alt={'logo'}
                />
            </Link>
            <nav>
                <ul>
                    <li>
                        <Link to="mailto:cashoutnowllc@gmail.com">Support</Link>
                    </li>
                    <li>
                        <Link to="/" onClick={handleLogout}>Logout</Link>
                    </li>
                </ul>
            </nav>
        </div>
    </header>;
}
