import { useState, useEffect } from 'react';
import { createPlacement, getPlacements, updatePlacement, getPlacementApiKey } from '../../services/publisherService';
import classes from './publisherDashboard.module.css';
import { useAuth } from '../../hooks/useAuth';

export default function Placements() {
    const { user } = useAuth();
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [placements, setPlacements] = useState([]);
    const [selectedPlacement, setSelectedPlacement] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        url: '',
        platform: ''
    });
    const [editData, setEditData] = useState({
        name: '',
        url: '',
        platform: '',
        currencySingular: '',
        currencyPlural: '',
        conversionRate: 0,
        userSplitPercentage: 0,
        postbackUrl: '',
        apiKey: null
    });

    useEffect(() => {
        loadPlacements();
    }, []);

    const loadPlacements = async () => {
        try {
            const data = await getPlacements();
            setPlacements(data);
        } catch (error) {
            console.error('Error loading placements:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await createPlacement(formData);
            setShowModal(false);
            setFormData({ name: '', url: '', platform: '' });
            loadPlacements();
        } catch (error) {
            console.error('Error creating placement:', error);
        }
    };

    const handleEdit = async (placement) => {
        setSelectedPlacement(placement);
        try {
            const apiKey = await getPlacementApiKey(placement._id);
            setEditData({
                name: placement.name,
                url: placement.url,
                platform: placement.platform,
                currencySingular: placement.currencySingular || '',
                currencyPlural: placement.currencyPlural || '',
                conversionRate: placement.conversionRate || 0,
                userSplitPercentage: placement.userSplitPercentage || 0,
                postbackUrl: placement.postbackUrl || '',
                apiKey: apiKey || null
            });
        } catch (error) {
            console.error('Error fetching API key:', error);
            setEditData({
                name: placement.name,
                url: placement.url,
                platform: placement.platform,
                currencySingular: placement.currencySingular || '',
                currencyPlural: placement.currencyPlural || '',
                conversionRate: placement.conversionRate || 0,
                userSplitPercentage: placement.userSplitPercentage || 0,
                postbackUrl: placement.postbackUrl || '',
                apiKey: null
            });
        }
        setShowEditModal(true);
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            await updatePlacement(selectedPlacement._id, editData);
            setShowEditModal(false);
            loadPlacements();
        } catch (error) {
            console.error('Error updating placement:', error);
        }
    };

    return (
        <div className={classes.placementsContainer}>
            <div className={classes.header}>
                <h2>Active Placements</h2>
                <button className={classes.addButton} onClick={() => setShowModal(true)}>+ New Placement</button>
            </div>

            <div className={classes.tableContainer}>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>URL</th>
                            <th>Platform</th>
                            <th>Created</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {placements.map(placement => (
                            <tr key={placement._id}>
                                <td>{placement.name}</td>
                                <td>{placement.url}</td>
                                <td>{placement.platform}</td>
                                <td>{new Date(placement.createdAt).toLocaleDateString()}</td>
                                <td>
                                    <button 
                                        className={classes.gearButton}
                                        onClick={() => handleEdit(placement)}
                                    >
                                        Settings
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {showModal && (
                <div className={classes.modal}>
                    <div className={classes.modalContent}>
                        <h2>New Placement</h2>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.formGroup}>
                                <label>Name:</label>
                                <input
                                    type="text"
                                    value={formData.name}
                                    onChange={(e) => setFormData({...formData, name: e.target.value})}
                                    required
                                />
                            </div>
                            <div className={classes.formGroup}>
                                <label>URL:</label>
                                <input
                                    type="url"
                                    value={formData.url}
                                    onChange={(e) => setFormData({...formData, url: e.target.value})}
                                    required
                                />
                            </div>
                            <div className={classes.formGroup}>
                                <label>Platform:</label>
                                <select
                                    value={formData.platform}
                                    onChange={(e) => setFormData({...formData, platform: e.target.value})}
                                    required
                                >
                                    <option value="">Select Platform</option>
                                    <option value="android">Android</option>
                                    <option value="ios">iOS</option>
                                    <option value="desktop / mobile web">Desktop / Mobile Web</option>
                                </select>
                            </div>
                            <div className={classes.modalActions}>
                                <button type="button" onClick={() => setShowModal(false)}>Cancel</button>
                                <button type="submit">Create</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {showEditModal && (
                <div className={classes.modal}>
                    <div className={classes.modalContent}>
                        <h2>Edit Placement</h2>
                        <form onSubmit={handleEditSubmit}>
                            <h3>Basic Info</h3>
                            <div className={classes.formGroup}>
                                <label>Name:</label>
                                <input
                                    type="text"
                                    value={editData.name}
                                    onChange={(e) => setEditData({...editData, name: e.target.value})}
                                    required
                                />
                            </div>
                            <div className={classes.formGroup}>
                                <label>URL:</label>
                                <input
                                    type="url"
                                    value={editData.url}
                                    onChange={(e) => setEditData({...editData, url: e.target.value})}
                                    required
                                />
                            </div>
                            <div className={classes.formGroup}>
                                <label>Platform:</label>
                                <select
                                    value={editData.platform}
                                    onChange={(e) => setEditData({...editData, platform: e.target.value})}
                                    required
                                >
                                    <option value="">Select Platform</option>
                                    <option value="android">Android</option>
                                    <option value="ios">iOS</option>
                                    <option value="desktop / mobile web">Desktop / Mobile Web</option>
                                </select>
                            </div>

                            <h3>Currency</h3>
                            <div className={classes.formGroup}>
                                <label>Currency Name (Singular):</label>
                                <input
                                    type="text"
                                    value={editData.currencySingular}
                                    onChange={(e) => setEditData({...editData, currencySingular: e.target.value})}
                                />
                            </div>
                            <div className={classes.formGroup}>
                                <label>Currency Name (Plural):</label>
                                <input
                                    type="text"
                                    value={editData.currencyPlural}
                                    onChange={(e) => setEditData({...editData, currencyPlural: e.target.value})}
                                />
                            </div>
                            <div className={classes.formGroup}>
                                <label>User's Currency Conversion Rate:</label>
                                <input
                                    type="number"
                                    step="0.01"
                                    value={editData.conversionRate}
                                    onChange={(e) => setEditData({...editData, conversionRate: parseFloat(e.target.value)})}
                                />
                                <small>The amount of your currency that equals $1.00 USD, prior to revenue being split between you (in USD) and the user (in your placement's currency).</small>
                            </div>
                            <div className={classes.formGroup}>
                                <label>Currency Split to User (%):</label>
                                <input
                                    type="number"
                                    min="0"
                                    max="100"
                                    value={editData.userSplitPercentage}
                                    onChange={(e) => setEditData({...editData, userSplitPercentage: parseFloat(e.target.value)})}
                                />
                                <small>This is the percentage of revenue that should be given to users in the form of your placement's currency. The remaining percentage is your cut.</small>
                            </div>

                            <h3>Integration</h3>
                            <div className={classes.formGroup}>
                                <label>Postback URL:</label>
                                <input
                                    type="text"
                                    value={editData.postbackUrl}
                                    onChange={(e) => setEditData({...editData, postbackUrl: e.target.value})}
                                    placeholder="https://your-domain.com/postback"
                                />
                                <small className={classes.postbackNote}>
                                    Available macros:<br/>
                                    {'{userID}'} - The userID passed to us in the integration URL<br/>
                                    {'{transactionID}'} - The transactionID of this conversion in our database<br/>
                                    {'{ip}'} - The user's IP<br/>
                                    {'{revenue}'} - The revenue, in USD, you earned from this conversion<br/>
                                    {'{amount}'} - The amount of currency the user should receive as a reward. This can be configured on the "Currency" tab
                                </small>
                            </div>

                            <h3>Integration Code</h3>
                            <div className={classes.formGroup}>
                                <label>Direct Link:</label>
                                <div className={classes.copyContainer}>
                                    <input
                                        type="text"
                                        readOnly
                                        value={`https://offerwall.rewardrocket.app/${user?.id}/${selectedPlacement._id}/{YOUR_USER_ID}`}
                                        onClick={(e) => e.target.select()}
                                    />
                                    <button 
                                        type="button"
                                        onClick={() => navigator.clipboard.writeText(`https://offerwall.rewardrocket.app/${user?.id}/${selectedPlacement._id}/{YOUR_USER_ID}`)}
                                        className={classes.copyButton}
                                    >
                                        Copy
                                    </button>
                                </div>
                                <small>Replace {'{YOUR_USER_ID}'} with your internal user identifier</small>
                            </div>

                            <div className={classes.formGroup}>
                                <label>Iframe Code:</label>
                                <div className={classes.copyContainer}>
                                    <input
                                        type="text"
                                        readOnly
                                        value={`<iframe src="https://offerwall.rewardrocket.app/${user?.id}/${selectedPlacement._id}/{YOUR_USER_ID}" width="100%" height="800" frameborder="0"></iframe>`}
                                        onClick={(e) => e.target.select()}
                                    />
                                    <button 
                                        type="button"
                                        onClick={() => navigator.clipboard.writeText(`<iframe src="https://offerwall.rewardrocket.app/${user?.id}/${selectedPlacement._id}/{YOUR_USER_ID}" width="100%" height="800" frameborder="0"></iframe>`)}
                                        className={classes.copyButton}
                                    >
                                        Copy
                                    </button>
                                </div>
                                <small>Replace {'{YOUR_USER_ID}'} with your internal user identifier</small>
                            </div>

                            <h3>API Access</h3>
                            <div className={classes.formGroup}>
                                <label>API Key:</label>
                                <div className={classes.copyContainer}>
                                    <input
                                        type="text"
                                        readOnly
                                        value={editData.apiKey || 'API Key not available for this placement'}
                                        onClick={(e) => e.target.select()}
                                    />
                                    {editData.apiKey && (
                                        <button 
                                            type="button"
                                            onClick={() => navigator.clipboard.writeText(editData.apiKey)}
                                            className={classes.copyButton}
                                        >
                                            Copy
                                        </button>
                                    )}
                                </div>
                                <small>Use this API key to access offers programmatically. Keep this key secret!</small>
                            </div>

                            <div className={classes.modalActions}>
                                <button type="button" onClick={() => setShowEditModal(false)}>Cancel</button>
                                <button type="submit">Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}
