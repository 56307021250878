import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import classes from './conversionEvents.module.css';

const QRCodeModal = ({ link, onClose }) => {
  return (
    <div className={classes.modalOverlay}>
      <div className={classes.modalContent}>
        <button className={classes.closeButton} onClick={onClose}>×</button>
        <h2>Scan the QR Code</h2>
        <QRCodeSVG value={link} size={256} />
        <p>Scan this QR code with your mobile device to access the offer.</p>
      </div>
    </div>
  );
};

export default QRCodeModal;