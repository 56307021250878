import axios from 'axios';

export const publisherLogin = async ({ email, password }) => {
    const { data } = await axios.post('/api/publishers/login', { email, password });
    localStorage.setItem('user', JSON.stringify(data));
    return data;
};

export const publisherRegister = async ({ email, password, companyName, telegramId, skypeId }) => {
    const { data } = await axios.post('/api/publishers/register', {
        email,
        password,
        companyName,
        telegramId,
        skypeId
    });
    localStorage.setItem('user', JSON.stringify(data));
    return data;
};

export const createPlacement = async (placementData) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const { data } = await axios.post('/api/publishers/placements', {
        ...placementData,
        publisherId: user.id
    });
    return data;
};

export const getPlacements = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const { data } = await axios.get(`/api/publishers/placements/${user.id}`);
    return data;
};

export const getPlacement = async (publisherId, placementId) => {
    const { data } = await axios.get(`/api/publishers/${publisherId}/placements/${placementId}`);
    return data;
};

export const updatePlacement = async (placementId, updateData) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const { data } = await axios.put(`/api/publishers/placements/${user.id}/${placementId}`, updateData);
    return data;
};

export const getPlacementApiKey = async (placementId) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || !user.id) {
        throw new Error('User not found');
    }
    const { data } = await axios.get(`api/publishers/${user.id}/placements/${placementId}/api-key`);
    return data.apiKey;
};
